import React from 'react';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

export const sections = [
    { title: 'Research', url: '#' },
    { title: 'Personal Journey', url: '#' },
    { title: 'Tips', url: '#' },
    { title: 'Interviews', url: '#' },
  ];

  export const langs = [
    { title: 'English', url: '#' },
    { title: 'French', url: '#' },
    { title: 'German', url: '#' },
    { title: 'Spanish', url: '#' },
  ];

const TagsHeader = ({
    tags
}) => 

    <Grid container 
          justifyContent='space-around' 
          sx={{mt: 1, mb: 2}}>
        {langs.map(s => 
            <Grid item key={s.title}>
                <Link href="#" sx={{color: '#6415ff'}}>
                    {s.title}
                </Link>
            </Grid>
        )}
         <Grid item >
                |
        </Grid>
        {tags && tags.length>0 && tags.map(s => 
            <Grid item key={s.tag_name}>
                <Link href="#" sx={{color: '#6415ff'}}>
                    {s.tag_name}
                </Link>
            </Grid>
        )}
    </Grid>

export default TagsHeader