import React, { useState } from "react";
import { queryClient } from "../..";
import { 
    useMutation, 
} from "react-query"

import {
    addFeed,
} from '@queries/statsQueries'

import { getTranslations } from '../../utils'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';

import BasicIconButton from '@components/Buttons/BasicIconButton'
import SnackAlert from '@components/Dialogs/SnackAlert'
import CountryDropDown from '@components/DropDowns/CountryDropDown'

export default function AddFeedCard({
    lang,
}){
    const { 
        addfeed, 
        feedadded,
        errorMsg,
     } = getTranslations()

    const [ link, setLink ] = useState('')
    const [ country, setCountry ] = useState('')
    const [ error, setError ] = useState()
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ msgType, setMsgType ] = useState('info')
    const [ snack, setSnack ] = useState(false)

    const handleLink = event => setLink(event.target.value)

    const handleCountry = event => setCountry(event.target.value)

    const handleError = () => setError(!error)

    const handleClose = () => setSnack(false)

    const showSnack = (type, message) => {
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }

    const addFeedMutation = useMutation(() => addFeed({
        lang, 
        link, 
        country, 
      }), {
        onSuccess: (data) => {
            queryClient.invalidateQueries('feeds')
            setLink('')
            setCountry('')
            showSnack('success', feedadded)
        },
        onError: (error) => {
            showSnack('error', errorMsg)
        },
        // onSettled: (data)=>{
        //   setLoading(false)
        // }
      })

    const addFeed1 = async () => {
        const validLink = link.startsWith('http')
        if(!validLink){
            return handleError()
        }

        if(link.length<5 || country.length<5){
            return handleError()
        }

        // setLoading(true)
        addFeedMutation.mutate()
    }

    return(
        <Card style={{margin:10}}>
            <CardContent>
                    <Grid container  alignItems="center"  justifyContent='space-between' >
                        <Grid item md={7}>
                            <TextField
                                style={{ width: '100%'}}
                                label="Feed" 
                                onChange={handleLink}
                            />
                        </Grid>
                        <Grid item md={2}>
                            <CountryDropDown
                                onChange={handleCountry}
                                value={country}
                                label='Country'
                            />
                        </Grid>
                        <Grid item >
                            <BasicIconButton  
                                actionFunc={addFeed1} 
                                title={addfeed}
                                size='large'
                                icon={<AddIcon />}
                            />
                        </Grid>
                    </Grid>
                    <SnackAlert 
                        snack={snack}
                        handleClose={handleClose}
                        msgType={msgType}
                        alertMsg={alertMsg}
                    /> 
            </CardContent>
        </Card>
    )
}


