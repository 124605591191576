import React from 'react'

import SectionCard from '@components/Cards/SectionCard'

export default function DashboardPage({
    items, 
}){

  return (
      <main >    
          {items.map(r => 
            <SectionCard key={r.title} {...r} />
          )}
      </main>
    )
  }
