import { 
    useMutation, 
 } from "react-query"
 import {queryClient} from '../index'

 
import {supabase} from "../supabase"

const table = 'tags'

export const createMember =  async ({
    email,
    name,
    langs,
    nativeLang,	
    timeZone,
    country, 
}) => {
    const user = supabase.auth.user()
    const { data, error } = await supabase
    .from(table)
    .insert({
        source: 'web',
        email,
        name,
        fcm_token: '',	
        langs,
        native_lang: nativeLang,	
        badge_count: 0,	
        link_count: 0,	
        translation_count: 0,	
        online: false,	
        role: 'basic',
        time_zone: timeZone,
        country,
        auth_id: user.id,
})

    if(error) {
        throw new Error(error.message)
    }

    return data
}

export const updateMember = async update => {
    const user = supabase.auth.user()
    const { data, error } = await supabase
        .from(table)
        .update(update)
        .eq('auth_id', user.id)

    if(error) {
        throw new Error(error.message)
    }

    return data
}

export const getMember = async () => {
    const user = supabase.auth.user()
    const { data, error } = await supabase
      .from(table)
      .select()
      .eq('auth_id', user.id)
      .single()
  
    if(error) {
      throw new Error(error.message)
    }
  
    if(!data) {
      throw new Error("Member not found")
    }
  
    return data
  }

export const getTags = async () => {
    const { data, error } = await supabase
    .from(table)
    .select(`
        id,
        tag_name 
        `)
    .order('tag_name')

if(error) {
    console.log(error)
    throw new Error(error.message)
}

if(!data) {
    throw new Error("Cluster not found")
}

return data
}
  
export const  useCreateMember = () => {
    const user = supabase.auth.user()
    return useMutation(() => createMember(user), {
        onSuccess: async (data) => {
        const { data: insertData, error: insertError } = await supabase
            .from(table)
            .insert({
                email: user.email,
                id: user.id, 
            })

        if(insertError) {
            throw insertError
        }

        return insertData
        }
    })
}

export const prefetchPosts = async () => await queryClient.prefetchQuery('posts',getTags())
