import React from "react"
import { Route, Switch } from "react-router-dom"

//protected routes pages

import Dashboard from "@pages/Dashboard"
import AddPost1 from "@pages/AddPost1"
import UpdatePost from "@pages/UpdatePost"
import PostList from "@pages/PostList"
import Profile from "@pages/Profile"
import SupportLogged from "@pages/SupportLogged"
import Search from "@pages/Search"
import LangRss from "@pages/LangRss"

import Stats from "@pages/Stats"
import LangStats from "@pages/LangStats"
import AuthorPostList from "@pages/AuthorPostList"

import SideNav from "@components/Nav/SideNav"
import AppBarHeader from "@components/Nav/AppBarHeader"
import { RssFeed } from "@mui/icons-material"

const routes = [ 
  { path: "/user/dashboard",  component: Dashboard, exact: true },
  { path: "/user/add_post",  component: AddPost1, exact: true },
  { path: "/user/update_post/:id",  component: UpdatePost, exact: false },
  { path: "/user/author_posts/:member_id",  component: AuthorPostList, exact: false },
  { path: "/user/posts",  component: PostList, exact: true },
  { path: "/user/profile",  component: Profile, exact: true },
  { path: "/user/support",  component: SupportLogged, exact: true },
  { path: "/user/search_posts",  component: Search, exact: true },
  { path: "/user/stats",  component: Stats, exact: true },
  { path: "/user/lang_stats/:lang",  component: LangStats, exact: true },
  { path: "/user/lang_rss/:lang",  component: LangRss, exact: true },

]

export default function User(){

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

    return(
      <div style={{ display: 'flex'}}
      >
        <AppBarHeader 
          handleDrawerToggle={handleDrawerToggle} 
        />
        <SideNav
          handleDrawerToggle={handleDrawerToggle}
          mobileOpen={mobileOpen}
        />
        <main 
          style={{ 
            flexGrow: 1,
            padding: 24,
          }}
        >
          <Switch key="router">
            {routes.map(route => (
              <Route
                key={route.path}
                exact={route.exact}
                path={route.path}
                component={route.component}
              />
            ))}
          </Switch>
        </main>
      </div>
    
  )
}
