import React from "react";

import { 
    useQuery,
} from "react-query"
  
import {
    getBlogImages,
} from '@queries/blogQueries'

import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography"
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Link from '@mui/material/Link'
import CardMedia from '@mui/material/CardMedia';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';

import { CardActionArea, Divider } from '@mui/material';

const imageSites = [
    {
        title: 'Unsplash',
        url: 'https://unsplash.com'
    },
    {
        title: 'Pexels',
        url: 'https://www.pexels.com'
    },
    {
        title: 'Pixabay',
        url: 'https://pixabay.com/'
    },
]

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const ImageCard = ({
    name,
    chooseImage,
    handleClose,
}) =>  {
    const fullName = `https://umggigxvzsmppkbirvxp.supabase.co/storage/v1/object/public/blogimages/${name}`
    const handleChoose = () =>{ 
        chooseImage(fullName )
        handleClose()
    }

    return <Grid item>
                <Card onClick={handleChoose} >
                    <CardActionArea> 
                        <CardMedia
                            component="img"
                            alt={name}
                            height="140"
                            image={fullName}
                        />
                    </CardActionArea>
                </Card>
            </Grid>
}
  
const ChoosePic = ({
    open,
    handleClose,
    chooseImage, 
}) => {

const { 
    // isLoading, 
    // isError, 
    // error, 
    data,
} = useQuery('blog_images', () => getBlogImages())

return  <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }} style={{backgroundColor: '#6415ff'}}> 
                <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Choose Image    
                </Typography>
                </Toolbar>
            </AppBar>
            <Box sx={{m:1}} />
            <Grid container justifyContent='space-between'>
                 <Grid item>
                     <Grid container  spacing={2} sx={{ml: 2}}>
                            <Grid item >
                                <Typography >
                                    Free Image Sites
                                </Typography>
                            </Grid>
                        {imageSites.map(s => 
                            <Grid item key={s.title}>
                                <Link href={s.url} target="_blank" color='#6415ff' >
                                    {s.title}
                                </Link>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item>
                    <Divider sx={{mt:3, mb: 3}} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}  sx={{ml: 2}}>
                        {data && data.map((img, i)=> 
                            <ImageCard 
                                key={i}
                                {...img} 
                                handleClose={handleClose}
                                chooseImage={chooseImage}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
  
  }

export default ChoosePic