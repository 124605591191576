import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { CardContent } from '@mui/material';

import PostListItem from '@components/ListItems/PostListItem'

export default function EditPostList({
    posts,
    published,
    title,
}) {
  const posts1 = posts.filter(p => p.published==published)
  return (
    <Card style={{height:'100%'}}>
      <CardContent>
        <Grid container  spacing={3}>
          <Grid item xs={12} >
            <Typography variant='h6' >
              {title}
            </Typography>
          </Grid>
          {posts1.map((p) => 
            <PostListItem {...p} key={p.id} />
          )}
      </Grid>
    </CardContent>
    </Card>
  );
}
