import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Chip from '@mui/material/Chip';
import FlagRound from '@components/ImageElements/FlagRound'

import { getTagColor } from '../../utils';

export default function PostListItem({
    id,
    title,
    published_date,
    members,
    tags,
    lang,
    featured,
    published,
}){
    
    return  <Grid item xs={12}>
                <Typography >
                    <Link href={`/user/update_post/${id}`}  style={{color: '#6415ff'}}>
                        {title}
                    </Link>
                </Typography>
                <Grid container spacing={1} alignItems='center'>
                    <Grid item  xs={12}>
                        <Typography variant="body1">
                            {new Date(published_date).toUTCString()} by <Link sx={{color: '#5d4037'}} href={`/user/author_posts/${members.id}`}>{members.name}</Link>
                        </Typography> 
                    </Grid>
                    <Grid item>
                        <FlagRound lang={lang} flagSize='xsmall'  />
                    </Grid>
                    <Grid item>
                        <Chip label={tags.tag_name} size='small'  sx={{backgroundColor: getTagColor(tags.tag_name) , color:'white'}} />
                    </Grid>
                    <Grid item>
                        {featured && <Chip label='Featured' size='small'  sx={{backgroundColor: '#6415ff', color:'white'}} />}
                    </Grid>
                    <Grid item>
                        {published && <Chip label='Published' size='small' sx={{backgroundColor: '#00e676', color:'white'}} />}
                    </Grid>
                </Grid>
            </Grid>
}