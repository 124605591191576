import { 
    useMutation, 
    useQuery,
 } from "react-query"
 import {queryClient} from '../index'

 
import {supabase} from "../supabase"

const table = 'users'
export const createUser =  async ({
    email,
    id,
}) => {
    const { data, error } = await supabase
    .from(table)
    .insert({
        source: 'web',
        email,
        id, 
    })

    if(error) {
        throw new Error(error.message)
    }

    return data
}

export const updateUser = async update => {
    const user = supabase.auth.user()
    const { data, error } = await supabase
        .from(table)
        .update(update)
        .eq('id', user.id)

    if(error) {
        throw new Error(error.message)
    }

    return data
}

export const getUser = async () => {
    const user = supabase.auth.user()
    const { data, error } = await supabase
      .from(table)
      .select()
      .eq('auth_id', user.id)
      .single()
  
    if(error) {
      throw new Error(error.message)
    }
  
    if(!data) {
      throw new Error("User not found")
    }
  
    return data
  }
  
export const  useCreateUser = () => {
    const user = supabase.auth.user()
    return useMutation(() => createUser(user), {
        onSuccess: async (data) => {
        const { data: insertData, error: insertError } = await supabase
            .from(table)
            .insert({
                email: user.email,
                id: user.id, 
            })

        if(insertError) {
          throw insertError
        }
        return insertData
        }
    })
}
 
export const useUser = () => useQuery(table, () => getUser())

export const prefetchUser = async () => await queryClient.prefetchQuery(table, getUser())
