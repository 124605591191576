import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton';
import red from '@mui/material/colors/red'

const LoadingIconButton = ({ 
    title,
    onClick,
    icon,
    loading,
    size='medium'
}) => 
    <LoadingButton
        size={size}
        loading={loading}
        fullWidth
        loadingPosition="start"
        startIcon={icon}
        onClick={onClick}
        variant="contained"
        sx={{ 
            backgroundColor: red['A400'],
            '&:hover': {
                backgroundColor: red['A700'],
            },
        }}
        >
        {title}
    </LoadingButton>

export default LoadingIconButton

