import React, { useState, useEffect } from "react"
import { queryClient } from "..";
import { useHistory } from "react-router-dom";
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';

import { 
  useMutation, 
  useQuery,
} from "react-query"

import {
  getTags, 
  createPost,
  uploadImage,
} from '@queries/blogQueries'
import {getMember} from '@queries/memberQueries'

import { isValidHttpUrl } from '../utils'
// import { functions, analytics } from '../../firebase'

import AddPostPage from '@components/Pages/AddPostPage1'

export default function AddPost(){

    const { quill, quillRef } = useQuill();

    let history = useHistory();

    const { 
      isLoading, 
      data: tags1,
     } = useQuery('tags', () => getTags())

    const { 
      isLoading: asdf, 
      data: user,
     } = useQuery('member', () => getMember())

    const authorName = !asdf && user ? user.name : ''
    const author_id = !asdf && user ? user.id : ''

    const tags = !isLoading && tags1 ? tags1 : []
    const firstTag = tags.length>0 ? tags[0].id : 1

    const [ alertMsg, setAlertMsg ] = useState('')
    const [ msgType, setMsgType ] = useState('info')
    const [ snack, setSnack ] = useState(false)
    const [ url, setSearchLink ] = useState('')
    const [ title, setTitle ] = useState('')
    const [ text, setText ] = useState('')
    const [ summary, setSummary ] = useState('')
    const [ lang, setLang ] = useState('en')
    const [ post_image, setPost_image ] = useState('')
    const [ tag, setTag ] = useState(firstTag)
    const [ loading, setLoading ] = useState(false)
    const [ open, setOpen] = useState(false)
    const [ showPreview, setShowPreview] = useState(false)

    const [value, setValue] = useState('');
    const [html, setHtml] = useState('');

    const handleLink = event => setSearchLink(event.target.value)
    const handleLang = event => setLang(event.target.value)
    const handleTag = event => setTag(event.target.value)
    const handlePost_image = event => setPost_image(event.target.value)

    const handleSummary = event => setSummary(event.target.value)
    const handleTitle = event => setTitle(event.target.value)
    const handleClose = () => setSnack(false)
    const handleCloseModal = () => setOpen(!open)
    const togglePreview = () => setShowPreview(!showPreview)

    const showSnack = (type, message) => {
      setLoading(false)
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }

    const addPostMutation = useMutation(() => createPost({
      title,
      lang, 
      featured: false,
      summary,
      article_text: text,
      post_image,
      tag,
      author_id
    }), {
      onSuccess: (data) => {
        const id = data[0].id
        queryClient.invalidateQueries('posts')
        showSnack('success', 'Success! New post added!')
        history.push(`/user/update_post/${ id}`);
      },
      onError: (error) => {
        showSnack('error', 'Error adding new post! ' + error)
      },
      onSettled: (data)=>{
        setLoading(false)
      }
    })

    const checkItem = () => {
      const chars = text.length
      const urlok = isValidHttpUrl(url)
      const ok = chars>7000 || chars<200 || title.length < 3 || !urlok ? false : true
      return ok
    }

    const addText1 = async () => {
      setLoading(true)
      addPostMutation.mutate()

        setHtml(quill.root.innerHTML)
    }

    // const insertToEditor = (url) => {
    //   const range = quill.getSelection();
    //   quill.insertEmbed(range.index, 'image', url);
    // };
  
    // Upload Image to Image Server such as AWS S3, Cloudinary, Cloud Storage, etc..
    const saveToServer = async (file) => {
      try{
        await uploadImage({
          file, 
          filePath: file.name,
        })
        showSnack('success', 'Image upload success!')
      } catch(error){
        showSnack('error', 'Image upload error!')
      }
    
    };
  
    // Open Dialog to select Image File
    const selectLocalImage = () => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.click();
  
      input.onchange = () => {
        const file = input.files[0];
        saveToServer(file);
      };
    };
  
    useEffect(() => {
      const insertToEditor = (url) => {
        const range = quill.getSelection();
        quill.insertEmbed(range.index, 'image', url);
      };

      const imageHandler = () => {
        var value = prompt('Copy paste the image url here.');
        if(value){
          insertToEditor(value);
        }
      }

      if (quill) {
        // Add custom handler for Image Upload
       quill.getModule('toolbar').addHandler('image', imageHandler);
      }
    }, [quill]);

    return <AddPostPage 
            lang={lang}
            url={url}
            title={title}
            text={text}
            loading={loading}
            handleLink={handleLink}
            handleText={setText}
            handleTitle={handleTitle}
            addText={addText1}
            snack={snack}
            msgType={msgType}
            alertMsg={alertMsg}
            handleClose={handleClose}
            checkItem={checkItem}
            handleLang={handleLang}
            handleSummary={handleSummary}
            summary={summary}
            tags={tags}
            tag={tag}
            handleTag={handleTag}
            post_image={post_image}
            handlePost_image={handlePost_image}
            authorName={authorName}
            value={value} 
            setValue={setValue}
            quillRef={quillRef}
            html={html}
            open={open}
            handleCloseModal={handleCloseModal}
            setPost_image={setPost_image}
            uploadImage={selectLocalImage}
            togglePreview={togglePreview}
            showPreview={showPreview}
          />
}