import React from 'react';
import Grid from '@mui/material/Grid';

import SocialList from '@components/Blog/SocialList'
import AboutCard from '@components/Blog/AboutCard'
// import TextField from '@mui/material/TextField';
// import BasicIconButton from '@components/Buttons/BasicIconButton'
// import SearchIcon from '@mui/icons-material/Search';

const Sidebar = () => {
    // const [ searchTerm, setSearchTerm]= useState('')
    // const handleSearchTerm = event => setSearchTerm(event.target.value)
    // const search = () => {
    //     console.log(searchTerm)
    // }
    return  <Grid item xs={12} md={4}>
                <AboutCard />
                {/* <TextField variant="outlined" onChange={handleSearchTerm} fullWidth sx={{mt:1, mb:1}}/>
                <BasicIconButton
                    actionFunc={search} 
                    title='Search'
                    icon={<SearchIcon />}
                 />  */}
                <SocialList />
            </Grid>
}
   

export default Sidebar;