import React from "react"
import { Route, Switch } from "react-router-dom"
import { ThemeProvider, createTheme } from '@mui/material/styles'

import Signin from "@pages/Signin"
import Auth from "@pages/Post"
import User from "@pages/User"
import NotFoundPage from '@components/Pages/NotFoundPage'

const theme = createTheme()

export const routes = [
  { path: "/", component: Signin, exact: true },
  { path: "/user",  component: User, exact: false },
  { path: "/signin",  component: Signin, exact: false },
  { path: "/auth",  component: Auth, exact: false },
]

export default function App(){
    return( 
    <ThemeProvider theme={theme}> 
        <Switch key="router">
          {routes.map(route => (
            <Route
              key={route.path}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          ))}
          <Route path="*" component={NotFoundPage} />
        </Switch>
    </ThemeProvider>
  )
}
