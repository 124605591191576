import React from 'react'
import { Link } from 'react-router-dom'

import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

  export default function SideBarItem({
    link, 
    component, 
    title,
  }){
    return (
     <Link to={link} >
        <ListItem button>
            <ListItemIcon sx={{color: '#6415ff'}}>
              {component}
            </ListItemIcon >
            <ListItemText primary={title}  />
        </ListItem>
    </Link>
  )
}