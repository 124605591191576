import React, { useState } from 'react'
import {queryClient} from '../../index'

import { 
  useMutation, 
} from "react-query"

import {updateMember} from '@queries/memberQueries'
import {updateEmail} from '@queries/authQueries'

import { 
  getTranslations, 
 } from '../../utils'

import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import EditIcon from '@mui/icons-material/Edit'

import SnackAlert from '@components/Dialogs/SnackAlert'
import LoadingIconButton from '@components/Buttons/LoadingIconButton'

export default function UpdateUser({
  name,
  email,
}){

    const curLang = getTranslations()
    const { 
      update,
      namesub,
      submit,
      emailsub,
    } = curLang


    const [ name1, setName ] = useState(name)
    const [ email1, setEmail ] = useState(email)
    const [ msgType, setMsgType ] = useState('info')
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ snack, setSnack ] = useState(false)
    const [ loading, setLoading ] = useState(false)

    const handleClose = () => setSnack(false)

    const handleName = event => setName(event.target.value)

    const handleEmail = event => setEmail(event.target.value)

    const showSnack = (type, message) => {
      setLoading(false)
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }

    const  updateMemberMutation = useMutation((update) => updateMember(update), {
      onSuccess: (data) => {
        queryClient.setQueryData('member', data[0])
        showSnack('success', 'Updated profile!')
      },
      onError: (error) => {
        showSnack('error', 'Error updating profile!')
      },
    })

    const updateProfile = async () => {

      if (email !== email1){
        try {
          await updateEmail({email: email1})
        } catch(error){
          showSnack('error', 'Error updating email!')
        }
      
      }
     
      updateMemberMutation.mutate({
        name: name1, 
        email: email1,
      })
    }

    return(
      <Grid container spacing={1}>
            <Grid item xs={12}> 
              <Card>
                <CardContent>
                  <Typography variant={'h5'} 
                    sx={{ color:'#6415ff' }}
                  >
                    {update} Profile
                  </Typography>
                </CardContent>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <CardContent>
                  <TextField 
                    required
                    value={name1} 
                    onChange={handleName} 
                    sx={{ width: '100%'}}
                    label={namesub}
                  />
                </CardContent>
                <CardContent>
                  <TextField 
                    value={email1} 
                    onChange={handleEmail} 
                    sx={{ width: '100%'}}
                    label={emailsub}  
                    required
                  />
                </CardContent>
                <CardContent>
                  <LoadingIconButton
                    loading={loading}
                    icon={<EditIcon />}
                    onClick={updateProfile} 
                    title={submit}
                  />
                </CardContent> 
              </Card>
            </Grid> 
           
            <SnackAlert 
              snack={snack}
              handleClose={handleClose}
              msgType={msgType}
              alertMsg={alertMsg}
            />
          </Grid>
  )
}