import React, {useMemo} from "react";

import { Chart } from "react-charts";

export default function DayCountChart({sequences}){

  const primaryAxis = React.useMemo(
    () => ({
      getValue: datum => datum.day,
    }),
    []
  )

  const secondaryAxes = React.useMemo(
    () => [
      {
        getValue: datum => datum.total,
      },
    ],
    []
  )

  const data = useMemo(() => [
    {
      label: 'Articles',
      data: sequences
    },
  ], [sequences])

    return(
      <Chart
        style={{marginLeft:10, marginRight:20}}
        options={{
          data,
          primaryAxis,
          secondaryAxes,
          defaultColors:['#6415ff']
        }}
      />
  )
}