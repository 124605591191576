import React from "react";
import { Link as RouterLink } from 'react-router-dom';

import Button from '@mui/material/Button'

export default function BasicLinkIconButton({
    title,
    to,
    icon,
    size="medium"
}){

  return(
      <Button 
        sx={{
          color:'white',
          backgroundColor: '#6415ff',
          '&:hover': {
            backgroundColor: '#5010CC',
          },
        }}
        size={size}
        to={to}
        startIcon={icon}
        component={RouterLink} 
        variant="contained" 
        onClick={()=>{}}
      >
        {title}
      </Button>
  )
}