import React from 'react'
import { useParams } from "react-router-dom"

import { 
  useQuery,
} from "react-query"

import {
  langStats, 
  getMonthylyArts,
} from '@queries/statsQueries'

import LangStatsPage from '@components/Pages/LangStatsPage'

export default function Stats(){

  const title = 'Statistics'

  const {
    lang, 
  } = useParams()


  const { 
    isLoading: isLoadingStats, 
    // isError, 
    // error, 
    data: stats,
   } = useQuery('stats', () => langStats({lang}))

   const { 
    // isLoading: isLoadingStats1, 
    // isError, 
    // error, 
    data: monthlyArts,
   } = useQuery('monthly_arts', () => getMonthylyArts(lang))

   const counts = !isLoadingStats ? stats.count : 0
   const scrapes = !isLoadingStats ?  stats.recent : []
   const sequences =!isLoadingStats && stats!==null ? stats.monthCount  : []
   const monthlyArtsCount = !isLoadingStats && monthlyArts ? monthlyArts : []

  return <LangStatsPage 
          title={title}
          lang={lang}
          counts={counts}
          scrapes={scrapes}
          monthlyArtsCount={monthlyArtsCount}
          sequences={sequences}
          loading={isLoadingStats}
        />
  }
