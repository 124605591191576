import { createClient } from '@supabase/supabase-js'

const dev = !process.env.NODE_ENV === 'development'

const localUrl = 'http://localhost:54321'
const localAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiJ9.ZopqoUt20nEV9cklpv9e3yw3PVyZLmKs5qLD6nGL1SI'

const supabaseUrl = dev ? localUrl :  process.env.REACT_APP_SUPABASE_URL
const supabaseAnonKey = dev ? localAnonKey  :  process.env.REACT_APP_SUPABASE_ANON_KEY

export const supabase = createClient(supabaseUrl, supabaseAnonKey)