import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton';

const LoadingIconButton = ({ 
    title,
    onClick,
    icon,
    loading,
    size='medium'
}) => 
    <LoadingButton
        size={size}
        loading={loading}
        fullWidth
        loadingPosition="start"
        startIcon={icon}
        onClick={onClick}
        variant="contained"
        sx={{ 
            backgroundColor: '#6415ff',
            '&:hover': {
                backgroundColor: '#5010CC',
            },
        }}
        >
        {title}
    </LoadingButton>

export default LoadingIconButton

