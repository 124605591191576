import React from 'react'
import { 
  useQuery,
} from "react-query"

import {getMember} from '@queries/memberQueries'

import Grid from '@mui/material/Grid'

import UpdateUser from '@components/Profile/UpdateUser'

export default function Profile(){

  const { isLoading, data: user } = useQuery('member', () => getMember())

  return(
    <main style={{ flexGrow: 1 }}>
       {!isLoading && user && 
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <UpdateUser {...user} />
        </Grid>
      </Grid>
      }
    
    </main>
  )
}