import React from 'react';

import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';


import TagsHeader from '@components/Blog/TagsHeader'
import BlogHeader from '@components/Blog/BlogHeader'
import PostContent  from '@components/Blog/PostContent'

export default function PostPage({
  isLoading, 
  isError,
  error,
  post,
  tags,
}) {
      const date =   post.published_date ? new Date(post.published_date).toUTCString()  : ''
      const title = post.title ? post.title : ''
      const authorName = post.members ? post.members.name  : ''
      const summary = post.summary ? post.summary : ''
      const article_text = post.article_text ? post.article_text : ''
      const tag = post.tags ? post.tags.tag_name : ''
      const post_image = post.post_image ? post.post_image : ''
      const lang = post.lang ?  post.lang : ''

      return  <Container maxWidth="lg">
                <BlogHeader />
                <Divider sx={{mt: 2, mb: 2}} /> 
                <TagsHeader tags={tags} />
                <Divider sx={{mt: 2, mb: 2}} /> 

                <PostContent 
                    date={date}
                    title={title}
                    authorName={authorName}
                    summary={summary}
                    article_text={article_text}
                    tag={tag}
                    post_image={post_image}
                    lang={lang}
                />
              </Container>
}