import React from "react";

import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Typography from "@mui/material/Typography"
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import PublishIcon from '@mui/icons-material/Publish';

import EditPostList  from '@components/Blog/EditPostList'
import BasicLinkIconButton  from '@components/Buttons/BasicLinkIconButton'
import LoadingIconButton1 from '@components/Buttons/LoadingIconButton1'
import SnackAlert from '@components/Dialogs/SnackAlert'

export default function PostListPage({ 
  posts,
  title,
  publishPost,
  loading,
  snack,
  msgType,
  alertMsg,
  handleClose,
 }){

    return(

      <main >
            <Grid container  
                  alignItems="center" 
                  spacing={1} 
                  justifyContent='space-between'
            >
              <Grid item> 
                <Card sx={{padding: 3}} > 
                  <Grid container  alignItems="center" 
                      spacing={4} 
                      justifyContent='space-between' >
                  <Grid item> 
                    <Typography variant="h5" sx={{color:'#6415ff'}}>
                      {title}
                    </Typography>
                  </Grid>
                  <Grid item> 
                    <LoadingIconButton1
                      onClick={publishPost}
                      title='Publish'
                      loading={loading}
                      icon={<PublishIcon />}
                    /> 
                  </Grid>
                  <Grid item> 
                    <BasicLinkIconButton 
                      title='Post Search'
                      to='/user/search_posts'
                      icon={<SearchIcon />}
                    />
                      &nbsp;
                    <BasicLinkIconButton 
                      title='Add Post'
                      to='/user/add_post'
                      icon={<AddIcon />}
                    />
                  </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12}> 
                <Grid container  
                    alignItems="start" 
                    spacing={2} 
                    justifyContent='space-between'
                >
                  <Grid item xs={12} md={6}>
                    <EditPostList 
                      posts={posts} 
                      published={false} 
                      title='Unpublished'
                    />
                  </Grid>
                  <Grid item  xs={12} md={6}>
                    <EditPostList 
                      posts={posts} 
                      published={true} 
                      title='Published'
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          
          <SnackAlert 
            snack={snack}
            handleClose={handleClose}
            msgType={msgType}
            alertMsg={alertMsg}
          /> 
        </main>
    )
}