import React from 'react'
import { Typography } from '@mui/material';

import LangStatHeader from '@components/Stats/LangStatHeader'
import DayCountChart from '@components/Stats/DayCountChart'
import MonthlyCountChart from '@components/Stats/MonthlyCountChart'
import RecentStats from '@components/Stats/RecentStats'
import AddFeedCard from '@components/Cards/AddFeedCard'

export default function StatsPage({
  lang,
  counts,
  scrapes,
  sequences,
  monthlyArtsCount,
  loading,
  error,
  errorMsg,
}){
 
  return (
      <main >  
        <LangStatHeader lang={lang} counts={counts} />
        <AddFeedCard lang={lang} />
        {scrapes &&
           <RecentStats scrapes={scrapes}  />
        }  
        <Typography variant='h6' sx={{m:2}}>
          Articles Per Day Last 30 Days
        </Typography>
        <div style={{height: 300}}>
        {sequences && sequences.length>0 &&
          <DayCountChart 
            sequences={sequences} 
          />
        } 
        </div>
        <Typography variant='h6' sx={{m:2}}>
          Articles Per Month
        </Typography>
        <div style={{height: 300}}>
        {monthlyArtsCount && monthlyArtsCount.length>0 &&
          <MonthlyCountChart
            sequences={monthlyArtsCount} 
          />
        }  
        </div>
      </main>
    )
  }
