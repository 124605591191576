import  React from 'react';
import { useParams } from "react-router-dom"
import { 
    useQuery,
  } from "react-query"
  
import {getPosts} from '@queries/blogQueries'
import {getTags} from '@queries/tagsQueries'

import PostPage from '@components/Blog/PostPage'
export default function Post(){
    const {
        id, 
      } = useParams()
    const { isLoading, isError, error, data: posts } = useQuery('posts', () => getPosts())
    const posts1 = !isLoading ? posts: []
    const post = !isLoading ? posts1.filter(p => p.id==id)[0] : {}
    const {  data: tags } = useQuery('tags', () => getTags())
    return  <PostPage 
                post={post}
                isLoading={isLoading} 
                isError={isError} 
                error={error}  
                tags={tags} 
            />
}