import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import gray from '@mui/material/colors/grey';
import Divider from '@mui/material/Divider';

const title = 'About'

const AboutCard = () => 
        <Paper elevation={0} style={{ padding: 20, backgroundColor: gray[200] }}>
            <Typography variant="h6" gutterBottom color='#6415ff' >
                {title}
            </Typography>
            <Divider sx={{mt: 1, mb: 1}} /> 
            <Typography>
                Langa Learn helps you learn a language with material you are actually interested in.
            </Typography>
            <div style={{height:16}} />
            <Typography>
                The blog provides you with the latest on practical language learning tips, language learning research, and stories about the language learning journeys of others learners. 
            </Typography>
        </Paper>

export default AboutCard