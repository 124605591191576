import * as React from 'react';
import { langSwitch } from '../../utils';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import Sidebar from '@components/Blog/SideBar';
import FlagRound from '@components/ImageElements/FlagRound';

export default function PostContent({
    date,
    title,
    authorName,
    summary,
    article_text,
    tag,
    post_image,
    lang,
    html,
}) {
    
    const language = langSwitch(lang)

    return <Grid container spacing={5}  >
        <Grid
          item
          xs={12}
          md={8}>
            <Grid container spacing={2} >
              <Grid item >
                <Typography variant="h3" >
                  {title}
                </Typography>
              </Grid>
                <Grid item>
                  <Grid container spacing={1} justifyContent='space-between' alignItems='center' >
                    <Grid item >
                      <Typography variant="body1" color='textSecondary'>
                        {date} by {authorName}
                      </Typography>
                    </Grid>
                    <Grid item >
                    <Button  size='small' sx={{color: '#6415ff'}}  >
                        <FlagRound lang={lang} flagSize='xsmall' /> &nbsp; {language} 
                    </Button>
                      <Button  size='small' sx={{color: '#6415ff'}}  >
                        {tag} 
                      </Button>
                  </Grid>
                </Grid>
                <Grid item  xs={12}>
                  <Typography variant="h6" color='textSecondary' sx={{mt:2, mb:2}}>
                    {summary}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                <Paper
                  sx={{
                    height:200,
                    position: 'relative',
                    backgroundColor: 'grey.800',
                    color: '#fff',
                    mb: 2,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundImage: `url(${post_image})`,
                  }}
                >
                </Paper>
              </Grid>
                <Grid item >
                  <div dangerouslySetInnerHTML={{ __html: html }}  />
                </Grid>
              </Grid>
              </Grid>
        </Grid>
        <Sidebar />
      </Grid>
}