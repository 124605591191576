import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import {supabase} from "../supabase"
import {
  slugify, 
} from '../utils'
import { queryClient } from "..";

import { 
  useMutation, 
  useQuery,
} from "react-query"

import {
  getTags, 
  getPost, 
  updatePost,
} from '@queries/blogQueries'

// import { postData } from "../utils";

import {getMember} from '@queries/memberQueries'

import UpdatePostPage from '@components/Pages/UpdatePostPage'

export default function UpdatePost(){

    const { 
      id, 
    } = useParams()

    const { 
      isLoading, 
      // isError, 
      data: tags1,
     } = useQuery('tags', () => getTags())

    const { isLoading: asdf, 
      // isError: qwer, 
      data: user,
     } = useQuery('member', () => getMember())
    const { 
      // isLoading: asdf1, 
      // isError: qwer1, 
      data: post,
     } = useQuery('post', () => getPost({id}))

    const authorName = !asdf && user ? user.name : ''

    const tags = !isLoading && tags1 ? tags1 : []
    const firstTag = tags.length>0 ? tags[0].id : 1

    const [ alertMsg, setAlertMsg ] = useState('')
    const [ msgType, setMsgType ] = useState('info')
    const [ snack, setSnack ] = useState(false)
    const [ title, setTitle ] = useState('')
    const [ text, setText ] = useState('')
    const [ summary, setSummary ] = useState('')
    const [ featured, setFeatured ] = useState(false)
    const [ lang, setLang ] = useState('en')
    const [ post_image, setPost_image ] = useState('')
    const [ tag, setTag ] = useState(firstTag)
    const [ published, setPublished ] = useState(false)
    const [ loading, setLoading ] = useState(false)

    const handleLang = event => setLang(event.target.value)
    const handleTag = event => setTag(event.target.value)
    const handlePost_image = event => setPost_image(event.target.value)
    const handleSummary = event => setSummary(event.target.value)
    const handleTitle = event => setTitle(event.target.value)
    const handleClose = () => setSnack(false)
    const handleFeatured = () => setFeatured(!featured)

    const showSnack = (type, message) => {
      setLoading(false)
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }

    const updatePostMutation = useMutation(() => updatePost({
      title,
      slug: slugify(title),
      lang, 
      summary,
      article_text: text,
      post_image,
      tag_id: tag,
      featured,
      id,
    }), {
      onSuccess: (data) => {
        queryClient.invalidateQueries('posts')
        queryClient.invalidateQueries('post')
        showSnack('success', 'Success! Post updated!')
      },
      onError: (error) => {
        showSnack('error', 'Error updating post! ' + error)
      },
      onSettled: ()=>{
        setLoading(false)
      }
    })

    const updatePost1 = async () => {
      setLoading(true)
      updatePostMutation.mutate()
    }

    const publishPost1 = async () => {
      setLoading(true)
      const { data, error } = await supabase
      .from('blogs')
      .update({published: !published})
      .eq('id', id)

      if(error) {
          showSnack('error', 'Error updating post! ' + error)
      }

      if(data){
        try {
          const post = data[0]
          setPublished(post.published)
          queryClient.invalidateQueries('posts')
          queryClient.invalidateQueries('post')
          showSnack('success', 'Success! Set to Published')
        } catch(error) {
          console.log(error)
          showSnack('error', 'Error updating post! ' + error)
        }
      }
    }

    useEffect(()=>{
      if(!isLoading && post){
        setTitle(post.title)
        setText(post.article_text)
        setSummary(post.summary)
        setLang(post.lang)
        setPost_image(post.post_image)
        setTag(post.tag_id)
        setFeatured(post.featured)
        setPublished(post.published)
      }
     
    },[post, isLoading])

    return <UpdatePostPage
            lang={lang}
            title={title}
            text={text}
            loading={loading}s
            handleText={setText}
            handleTitle={handleTitle}
            addText={updatePost1}
            snack={snack}
            msgType={msgType}
            alertMsg={alertMsg}
            handleClose={handleClose}
            handleLang={handleLang}
            handleSummary={handleSummary}
            summary={summary}
            tags={tags}
            tag={tag}
            handleTag={handleTag}
            post_image={post_image}
            handlePost_image={handlePost_image}
            authorName={authorName}
            featured={featured}
            handleFeatured={handleFeatured}
            publishPost={publishPost1}
            published={published}
          />
}