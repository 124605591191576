import { 
    useMutation, 
} from "react-query"

import {supabase} from "../supabase"

import { 
    createUser, 
    updateUser,
} from './userQueries'
  
const login = async ({
    email, 
    password,
}) => {
  const { data, error } = await supabase.auth.signIn({
    email, 
    password
  })

  const updates = {
    id: data.user.id,
    last_seen: new Date(),
  }

  const { data: updateData,  error: updateError } = await updateUser(updates)

  if(error) {
    throw new Error(error.message)
  }

  if(updateError) {
    throw new Error(updateError.message)
  }

  return updateData
}

const completeSignUp = async ({
    lang,
    timeZone,
    country
}) => {

  const { data: updateData,  error: updateError } = await updateUser({
    lang,
    timeZone,
    country
})

  if(updateError) {
    throw new Error(updateError.message)
  }

  return updateData
}

const signUpEmail = async ({
    email, 
    password, 
}) => {
    const { data, error } = await supabase.auth.signUp({
      email, 
      password
    })

    const { data: insertData, error: insertError } = await  createUser({
        email: data.user.email,
        id: data.user.id
    })
  
    if(error) {
      throw new Error(error.message)
    }
  
    if(insertError) {
      throw new Error(insertError.message)
    }
  
    return insertData
}
const signUpProvider = async ({
    provider, 
}) => {
    const { data, error } = await supabase.auth.signUp({provider})

    const { data: insertData, error: insertError } = await  createUser({
        email: data.user.email,
        id: data.user.id
    })

    if(error) {
      throw new Error(error.message)
    }
  
    if(insertError) {
      throw new Error(insertError.message)
    }
  
    return insertData
}

export const updateEmail = async ({
  email,
}) => {
  const { user, error } = await supabase.auth.update({email})
  if(error) {
    throw new Error(error.message)
  }

  return user
}
 
export const useLogin = (credentials) =>  useMutation('login', () => login(credentials))
export const useSignUpEmail = ({ email, password })=> useMutation('signupemail', () => signUpEmail({email, password}))
export const useSignUpProvider = ({ provider })=> useMutation('signupprovider', () => signUpProvider({provider}))
export const useCompleteSignUp = ({ provider })=> useMutation('completesignup', () => completeSignUp({provider}))