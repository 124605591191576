import React from "react";
import { getTranslations } from '../../utils'

import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import FormControl from '@mui/material/FormControl'
import Typography from "@mui/material/Typography"
import TextSnippetIcon from '@mui/icons-material/Description'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import ImageIcon from '@mui/icons-material/Image'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import PreviewIcon from '@mui/icons-material/Preview';

import SnackAlert from '@components/Dialogs/SnackAlert'
import LoadingIconButton from '@components/Buttons/LoadingIconButton'
import BasicIconButton from '@components/Buttons/BasicIconButton'
import PostContent  from '@components/Blog/PostContent'
import ChoosePic from '@components/Dialogs/ChooseImage'

import LangDropdown from '@components/DropDowns/LangDropdown'
import { Divider } from "@mui/material";

export default function AddPostPage({ 
    lang, 
    title,
    text,
    loading,
    handleTitle,
    handleText,
    addText,
    errorText,
    snack,
    msgType,
    alertMsg,
    handleClose,
    summary, 
    handleSummary, 
    handleLang,
    tag,
    tags,
    handleTag,
    post_image,
    handlePost_image,
    authorName,
    quillRef,
    html,
    open,
    handleCloseModal,
    setPost_image,
    uploadImage,
    togglePreview,
    showPreview,
 }){

    const {
      en,
      es,
      fr,
      de,
   } = getTranslations()

    const langugages = [
      {lang:'de', language:de},
      {lang:'en', language:en},
      {lang:'es', language:es},
      {lang:'fr', language:fr},
    ]
  
    const date =  new Date().toUTCString() 
   
    const tagName = tags.length>0 ?  tags.filter(t => t.id == tag)[0].tag_name : ''

    return(
     
      <main >
       
            <Grid container  
                  alignItems="center" 
                  spacing={2} 
                  justifyContent='space-between'
            >
              <Grid item xs={12}> 
                <Typography variant="h5" sx={{color:'#6415ff'}}>
                  Add Post
                </Typography>
              </Grid>
              <Grid item xs={6}> 
                <FormControl sx={{ width: '100%'}}>
                  <TextField 
                    variant="outlined"
                    fullWidth
                    multiline
                    error={errorText}
                    sx={{ width:'100%'}}
                    required 
                    label="Title" 
                    value={title} 
                    onChange={handleTitle} 
                  />
                </FormControl>  
              </Grid>
              <Grid item  >
                <FormControl  fullWidth  sx={{minWidth: 150}}>
                  <InputLabel >
                    Tag
                  </InputLabel>
                  <Select
                    label='Tag'
                    value={tag}
                    onChange={handleTag}
                    sx={{backgroundColor: 'white'}}
                  >
                    {tags.map(t => 
                      <MenuItem key={t.id} value={t.id}>
                        {t.tag_name}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item  >
                <LangDropdown 
                  label='Language'
                  value={lang}
                  onChange={handleLang}
                  languages={langugages}
                />
              </Grid>
              <Grid item> 
                 <BasicIconButton 
                  size='large'
                  title={showPreview ? 'Show Preview' : 'Show Preview'}
                  actionFunc={togglePreview}
                  icon={<PreviewIcon />}  
                  />
              </Grid>
              <Grid item xs={12}> 
                <FormControl sx={{ width: '100%'}}>
                  <TextField 
                    variant="outlined"
                    fullWidth
                    multiline
                    error={errorText}
                    sx={{ width: '100%'}}
                    required 
                    label="Summary" 
                    value={summary} 
                    onChange={handleSummary} 
                  />
                </FormControl>
              </Grid>  
              <Grid item xs={9}> 
                <FormControl sx={{ width: '100%'}}>
                  <TextField 
                    variant="outlined"
                    fullWidth
                    multiline
                    error={errorText}
                    sx={{ width:'100%'}}
                    required 
                    label="Post Image" 
                    value={post_image} 
                    onChange={handlePost_image} 
                  />
                </FormControl>
              </Grid>
              <Grid item > 
                 <BasicIconButton 
                  size='large'
                  title='Pick'
                  actionFunc={handleCloseModal}
                  icon={<ImageIcon />}  
                  />
              </Grid>
              <Grid item> 
                 <BasicIconButton 
                  size='large'
                  title='Upload'
                  actionFunc={uploadImage}
                  icon={<CloudUploadIcon />}  
                  />
              </Grid>
              <Grid item xs={12}> 
                <div ref={quillRef} style={{height: 300}} />
              </Grid>
              <Grid item xs={12}> 
                <div style={{height:30}} />
              </Grid>
              <Grid item  >
                <LoadingIconButton
                  size='large'
                  onClick={addText}
                  title='Add Article'
                  loading={loading}
                  icon={<TextSnippetIcon />}
                  /> 
              </Grid>
            </Grid>
        
          {showPreview &&  
            <Card sx={{p:3, mt: 1}}>
              <Typography variant="h5" sx={{color:'#6415ff'}}>
                Preview
              </Typography>
              <Divider sx={{mt:1, mb:1}} />
              <PostContent
                html={html} 
                date={date}
                title={title}
                authorName={authorName}
                summary={summary}
                article_text={text}
                tag={tagName}
                post_image={post_image}
                lang={lang}
              />
            </Card>
          }
          <SnackAlert 
            snack={snack}
            handleClose={handleClose}
            msgType={msgType}
            alertMsg={alertMsg}
          /> 
          <ChoosePic 
            chooseImage={setPost_image}
            open={open}
            handleClose={handleCloseModal}
          />
        </main>
    )
}