import trans from './translations'
import {supabase} from './supabase'
// import { auth } from './firebase'

const deployUrl = process.env.REACT_APP_CF_DEPLOY

export const slugify = (...args) => {
  const value = args.join(' ')

  return value
      .normalize('NFD') // split an accented letter in the base letter and the acent
      .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
      .replace(/\s+/g, '-') // separator
}

export const postData = async (url = '', data = {}) => {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'no-cors', // no-cors, *cors, same-origin
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

export const postAuth = async (url = '', data = {}) => {
  // Default options are marked with *
  const session = supabase.auth.session()
  const token = session.access_token;

  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

export const postAuth1 = async (url = '', data = {}) => {
  // Default options are marked with *
  const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZS1kZW1vIiwicm9sZSI6ImFub24ifQ.625_WdcF3KHqz5amU0x2X5WWHP-OEs_4qj0ssLNHzTs'

  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

export const getTagColor = (tag) => {
  if(tag==='Learning Tips'){
    return '#009688'
  }
  if(tag==='Personal story'){
    return '#ff3d00'
  }
  if(tag==='Research'){
    return '#2979ff'
  }
}

export const deployCF = async () => {
  try{
    await fetch(deployUrl, {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json'
      },
    });
  } catch(error){
    throw error
  }
}

// outputs date with chose sepator - 03/11/2021
export const formatDate1 = (date, separator) => {
  var dd = date.getDate();
  var mm = date.getMonth()+1; 
  var yyyy = date.getFullYear();
  if(dd<10) 
  {
      dd='0'+dd;
  } 
  
  if(mm<10) 
  {
      mm='0'+mm;
  } 
  var yearstr = yyyy.toString()
  return mm+separator+dd+separator+yearstr;
}

export const langs = [
  {lang:'en',language:'English'},
  {lang:'fr',language:'French'},
  {lang:'de',language:'German'},
  {lang:'es',language:'Spanish'}
]

export function sortDate(array){

    return array.sort(function(a, b) {
      a = new Date(a.date);
      b = new Date(b.date);
      return a>b ? -1 : a<b ? 1 : 0;
    })
  } 

export const groupBy = (array,attr)=>{
  const groups = array.reduce((acc, value) => {
  // Group initialization
  var item = value[attr]

  if (!acc[item]) {
      acc[item] = [];
  }
  
  // Grouping
  acc[item].push(value);
  
  return acc;
  }, {});
  return groups
}

export function langSwitch(lang){
    switch (lang) {
      case 'fr':
        return "French";
      case 'de':
        return "German";
      case 'es':
        return "Spanish";
      case 'en':
        return "English";
      case 'it':
        return "Italian";
      case 'pt':
        return "Portuguese";
      case 'gr':
        return "Greek";
      case 'ru':
          return "Russian";
      case 'zh':
        return "Chinese";
      case 'jp':
        return "Japanese";
      case 'sv':
        return "Swedish";
      case 'da':
        return "Danish";
      default:
        return "English";
     }
  }

export function formatDate(date, separator){
  var dd = date.getDate();

  var mm = date.getMonth()+1; 
  var yyyy = date.getFullYear();
  if(dd<10) 
  {
      dd='0'+dd;
  } 
  
  if(mm<10) 
  {
      mm='0'+mm;
  } 

    var yearstr = yyyy.toString()
    var yy = yearstr.slice(2,4)
    return mm+separator+dd+separator+yy;
}

export const getUser = () => JSON.parse(localStorage.getItem('user'))

export const getTranslations = () => {
  const user = getUser()
  const storedLang = localStorage.getItem('pageLang')
  const landingLang = storedLang && storedLang.length > 0 ? storedLang : 'en'
  const nativeLang = user ? user.native_lang : landingLang
  // const nativeLang = 'de'
  return trans[nativeLang] ? trans[nativeLang] : 'en'
}

export function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export const times = [
  { tm:6,time:'6 AM' },  
  { tm:7,time:'7 AM' },  
  { tm:8,time:'8 AM' },  
  { tm:9,time:'9 AM' },  
  { tm:10,time:'10 AM' }, 
  { tm:11,time:'11 AM' },  
  { tm:12,time:'12 PM' },  
  { tm:13,time:'1 PM' },  
  { tm:14,time:'2 PM' },  
  { tm:15,time:'3 PM' }, 
  { tm:16,time:'4 PM' },  
  { tm:17,time:'5 PM' },  
  { tm:18,time:'6 PM' },
  { tm:19,time:'7 PM' },  
  { tm:20,time:'8 PM' },  
  { tm:21,time:'9 PM' }, 
  { tm:22,time:'10 PM' },  
  { tm:23,time:'11 PM' }
]

export function getTime(time){
  switch (time) {
    case 6:
      return '6 AM'
    case 7:
      return '7 AM'
    case 8:
      return '8 AM'
    case 9:
      return '9 AM'
    case 10:
      return '10 AM'
    case 11:
      return '11 AM'
    case 12:
      return '12 PM'
    case 13:
      return '1 PM'
    case 14:
      return '2 PM'
    case 15:
      return '3 PM'
    case 16:
      return '4 PM'
    case 17:
      return '5 PM'
    case 18:
      return '6 PM'
    case 19:
      return '7 PM'
    case 20:
      return '8 PM'
    case 21:
      return '9 PM'
    case 22:
      return '10 PM'
    case 23:
      return '11 PM'
    default:
      return ''
  }
}

export const minimalTimezoneSet = [
  '(GMT-11:00) Pago Pago', 
  '(GMT-10:00) Hawaii Time',
  '(GMT-10:00) Tahiti', 
  '(GMT-09:00) Alaska Time', 
  '(GMT-08:00) Pacific Time',
  '(GMT-07:00) Mountain Time',
  '(GMT-06:00) Central Time',
  '(GMT-05:00) Eastern Time', 
  '(GMT-04:00) Atlantic Time - Halifax', 
  '(GMT-03:00) Buenos Aires', 
  '(GMT-02:00) Sao Paulo',
  '(GMT-01:00) Azores', 
  '(GMT+00:00) London', 
  '(GMT+01:00) Berlin', 
  '(GMT+02:00) Helsinki', 
  '(GMT+03:00) Istanbul', 
  '(GMT+04:00) Dubai', 
  '(GMT+04:30) Kabul', 
  '(GMT+05:00) Maldives', 
  '(GMT+05:30) India Standard Time', 
  '(GMT+05:45) Kathmandu', 
  '(GMT+06:00) Dhaka', 
  '(GMT+06:30) Cocos', 
  '(GMT+07:00) Bangkok', 
  '(GMT+08:00) Hong Kong', 
  '(GMT+08:30) Pyongyang', 
  '(GMT+09:00) Tokyo', 
  '(GMT+09:30) Central Time - Darwin',
  '(GMT+10:00) Eastern Time - Brisbane', 
  '(GMT+10:30) Central Time - Adelaide', 
  '(GMT+11:00) Eastern Time - Melbourne, Sydney', 
  '(GMT+12:00) Nauru', 
  '(GMT+13:00) Auckland', 
  '(GMT+14:00) Kiritimati', 
];

export const times1 = [
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23'
];

export const countryList = [
	"Afghanistan",
	"Albania",
	"Algeria",
	"American Samoa",
	"Andorra",
	"Angola",
	"Anguilla",
	"Antarctica",
	"Antigua and Barbuda",
	"Argentina",
	"Armenia",
	"Aruba",
	"Australia",
	"Austria",
	"Azerbaijan",
	"Bahamas (the)",
	"Bahrain",
	"Bangladesh",
	"Barbados",
	"Belarus",
	"Belgium",
	"Belize",
	"Benin",
	"Bermuda",
	"Bhutan",
	"Bolivia (Plurinational State of)",
	"Bonaire, Sint Eustatius and Saba",
	"Bosnia and Herzegovina",
	"Botswana",
	"Bouvet Island",
	"Brazil",
	"British Indian Ocean Territory (the)",
	"Brunei Darussalam",
	"Bulgaria",
	"Burkina Faso",
	"Burundi",
	"Cabo Verde",
	"Cambodia",
	"Cameroon",
	"Canada",
	"Cayman Islands (the)",
	"Central African Republic (the)",
	"Chad",
	"Chile",
	"China",
	"Christmas Island",
	"Cocos (Keeling) Islands (the)",
	"Colombia",
	"Comoros (the)",
	"Congo (the Democratic Republic of the)",
	"Congo (the)",
	"Cook Islands (the)",
	"Costa Rica",
	"Croatia",
	"Cuba",
	"Curaçao",
	"Cyprus",
	"Czechia",
	"Côte d'Ivoire",
	"Denmark",
	"Djibouti",
	"Dominica",
	"Dominican Republic (the)",
	"Ecuador",
	"Egypt",
	"El Salvador",
	"Equatorial Guinea",
	"Eritrea",
	"Estonia",
	"Eswatini",
	"Ethiopia",
	"Falkland Islands (the) [Malvinas]",
	"Faroe Islands (the)",
	"Fiji",
	"Finland",
	"France",
	"French Guiana",
	"French Polynesia",
	"French Southern Territories (the)",
	"Gabon",
	"Gambia (the)",
	"Georgia",
	"Germany",
	"Ghana",
	"Gibraltar",
	"Greece",
	"Greenland",
	"Grenada",
	"Guadeloupe",
	"Guam",
	"Guatemala",
	"Guernsey",
	"Guinea",
	"Guinea-Bissau",
	"Guyana",
	"Haiti",
	"Heard Island and McDonald Islands",
	"Holy See (the)",
	"Honduras",
	"Hong Kong",
	"Hungary",
	"Iceland",
	"India",
	"Indonesia",
	"Iran (Islamic Republic of)",
	"Iraq",
	"Ireland",
	"Isle of Man",
	"Israel",
	"Italy",
	"Jamaica",
	"Japan",
	"Jersey",
	"Jordan",
	"Kazakhstan",
	"Kenya",
	"Kiribati",
	"Korea (the Democratic People's Republic of)",
	"Korea (the Republic of)",
	"Kuwait",
	"Kyrgyzstan",
	"Lao People's Democratic Republic (the)",
	"Latvia",
	"Lebanon",
	"Lesotho",
	"Liberia",
	"Libya",
	"Liechtenstein",
	"Lithuania",
	"Luxembourg",
	"Macao",
	"Madagascar",
	"Malawi",
	"Malaysia",
	"Maldives",
	"Mali",
	"Malta",
	"Marshall Islands (the)",
	"Martinique",
	"Mauritania",
	"Mauritius",
	"Mayotte",
	"Mexico",
	"Micronesia (Federated States of)",
	"Moldova (the Republic of)",
	"Monaco",
	"Mongolia",
	"Montenegro",
	"Montserrat",
	"Morocco",
	"Mozambique",
	"Myanmar",
	"Namibia",
	"Nauru",
	"Nepal",
	"Netherlands (the)",
	"New Caledonia",
	"New Zealand",
	"Nicaragua",
	"Niger (the)",
	"Nigeria",
	"Niue",
	"Norfolk Island",
	"Northern Mariana Islands (the)",
	"Norway",
	"Oman",
	"Pakistan",
	"Palau",
	"Palestine, State of",
	"Panama",
	"Papua New Guinea",
	"Paraguay",
	"Peru",
	"Philippines (the)",
	"Pitcairn",
	"Poland",
	"Portugal",
	"Puerto Rico",
	"Qatar",
	"Republic of North Macedonia",
	"Romania",
	"Russian Federation (the)",
	"Rwanda",
	"Réunion",
	"Saint Barthélemy",
	"Saint Helena, Ascension and Tristan da Cunha",
	"Saint Kitts and Nevis",
	"Saint Lucia",
	"Saint Martin (French part)",
	"Saint Pierre and Miquelon",
	"Saint Vincent and the Grenadines",
	"Samoa",
	"San Marino",
	"Sao Tome and Principe",
	"Saudi Arabia",
	"Senegal",
	"Serbia",
	"Seychelles",
	"Sierra Leone",
	"Singapore",
	"Sint Maarten (Dutch part)",
	"Slovakia",
	"Slovenia",
	"Solomon Islands",
	"Somalia",
	"South Africa",
	"South Georgia and the South Sandwich Islands",
	"South Sudan",
	"Spain",
	"Sri Lanka",
	"Sudan (the)",
	"Suriname",
	"Svalbard and Jan Mayen",
	"Sweden",
	"Switzerland",
	"Syrian Arab Republic",
	"Taiwan",
	"Tajikistan",
	"Tanzania, United Republic of",
	"Thailand",
	"Timor-Leste",
	"Togo",
	"Tokelau",
	"Tonga",
	"Trinidad and Tobago",
	"Tunisia",
	"Turkey",
	"Turkmenistan",
	"Turks and Caicos Islands (the)",
	"Tuvalu",
	"Uganda",
	"Ukraine",
	"United Arab Emirates (the)",
	"United Kingdom of Great Britain and Northern Ireland (the)",
	"United States Minor Outlying Islands (the)",
	"United States of America (the)",
	"Uruguay",
	"Uzbekistan",
	"Vanuatu",
	"Venezuela (Bolivarian Republic of)",
	"Viet Nam",
	"Virgin Islands (British)",
	"Virgin Islands (U.S.)",
	"Wallis and Futuna",
	"Western Sahara",
	"Yemen",
	"Zambia",
	"Zimbabwe",
	"Åland Islands"
];

export function getOffset(tz){
    const begin = tz.slice(4)
    var n = begin.lastIndexOf(')');
    return begin.substring(0,n)
}

