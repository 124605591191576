import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import {supabase} from '../../supabase'

import { createTheme, styled } from '@mui/material/styles';

import CssBaseline from '@mui/material/CssBaseline'
import Drawer from '@mui/material/Drawer'
import AccountCircle from '@mui/icons-material/AccountCircle'
import DynamicFeed from '@mui/icons-material/DynamicFeed'
import BarChartIcon from '@mui/icons-material/BarChart';

import List from '@mui/material/List'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

import SideBarItem from '../ListItems/SideBarItem'
import RedButton from '@components/Buttons/RedButton'

import logo from '../../assets/langa-logo-192.png'

const drawerWidth = 140
const theme = createTheme();

const SideDrawerTheme = styled('div')(({ theme }) => ({
  height: 63,
  backgroundColor:'#6415ff'
}));

const SideDrawerTheme1 = styled('div')(({ theme }) => ({
  backgroundColor: 'rgb(240, 246, 255)',
    height:'100vh'
}));

const DrawerTheme = styled('nav')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: drawerWidth,
    flexShrink: 0,
  },
}));

export default function SideNav({ 
  container, 
  children, 
  handleDrawerToggle, 
  mobileOpen,
}){
  const history = useHistory()

  const logout = async () => {
    const { error } = await supabase.auth.signOut()
    if(error){
      console.log('error logging out')
    }
    history.push({pathname: `/`})
  }

  const section1 = [ 
    {title: 'Posts', link:"/user/posts", query: '', component:<DynamicFeed />},
    {title: 'Stats', link:"/user/stats", query: '', component:<BarChartIcon />},
    {title: 'Profile', link:"/user/profile", query: '', component:<AccountCircle />},
    {title: 'Support', link:"/user/support", query: '', component:<HelpOutlineIcon />},
  ]

  const drawer = (
    <>
      <SideDrawerTheme>
        <Link to='/user/dashboard'>
          <img 
            src={logo} 
            style={{
              height:63,
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
            alt="langalogo"  
          /> 
        </Link>
      </SideDrawerTheme>
      <List>
        {section1.map(it => 
          <SideBarItem key={it.title} {...it} />)}
      </List>
        <div  style={{ marginLeft: 15}}>
          <RedButton 
            title='Logout'
            onClick={logout}
          >
            Log out
          </RedButton>
        </div>
      </>
  )

  return (
    <div style={{ display: 'flex'}}>
      <CssBaseline />
      <DrawerTheme>
        <nav >
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            sx={{
              width: drawerWidth,
              display: { sm: 'none', xs: 'block' }
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
              {drawer}
          </Drawer>
          <Drawer
            sx={{
              display: { sm: 'block ', xs: 'none' }
            }}
            classes={{
              paper: {width: drawerWidth},
            }}
            variant="permanent"
            open
          >
            <SideDrawerTheme1>
              {drawer}
            </SideDrawerTheme1> 
          </Drawer>
        </nav>
      </DrawerTheme>
      {children}
    </div>
  )
}
