import React from 'react'
import Button from '@mui/material/Button'
import red from '@mui/material/colors/red'

const RedButton = ({ 
    title,
    onClick,
    size='medium',
}) => 
    <Button
        size={size}
        onClick={onClick}
        variant="contained"
        sx={{ 
            backgroundColor: red['A400'],
            '&:hover': {
                backgroundColor: red['A700'],
            },
        }}
    >
        {title}
    </Button>

export default RedButton