import React, { useState } from 'react'
import {supabase} from '../supabase'

import { getTranslations } from '../utils'

import { getMember } from '@queries/memberQueries'

import SignInPage from '@components/Pages/SignInPage'

export default function SignIn({history}){

  const trans = getTranslations()
  const email1 = trans.emailsub
  const pwd = trans.pwd

  const [ email, setEmail ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ alert, setAlert ] = useState('')
  const [ loading, setLoading] = useState(false)

  const handleEmail = event => setEmail(event.target.value)
  const handlePassword = event => setPassword(event.target.value)

  const checkUser = async () => {
    try{
      const user = await getMember()
      if(!user || user.role!=='admin'){ 
        setAlert('You do not have access.')
        resetInput()
        return
      }
      return history.push('/user/dashboard')
     } catch(error){
       console.log(error)
       resetInput()
       setAlert('You do not have access.')
    }
  }

  const resetInput = () => {
    setEmail('')
    setPassword('')
  }

  const loginPassword = async () => {
    try {
      setLoading(true)
      const { error } = await supabase.auth.signIn({ email, password })
     if (error)  {
        setAlert(error.message)
     }
     checkUser()
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const inputs = [
    {
      name:'email',
      label:email1,
      handleFunc: handleEmail,
      value: email
    },
    {
      name:'password',
      label:pwd,
      handleFunc: handlePassword,
      value: password
    }
  ]

  return <SignInPage
          inputs={inputs} 
          loginPassword={loginPassword} 
          alert={alert}
          loading={loading}
        />
}