import React from 'react';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import LandingButton from '@components/Buttons/LandingButton'

const site = 'Langa Learn'
const title = 'Blog'

const BlogHeader = () =>
    <Grid container 
          justifyContent='space-between' 
          sx={{mt: 2}}>
        <Grid item>
            <Link
                href='/'
                variant="h5"
                sx={{color: '#6415ff'}}
            >
                {site}
            </Link>
        </Grid>
        <Grid item>
            <Link
                href='/blog'
                sx={{color: '#6415ff'}}
                variant="h5"
            >
                {title}
            </Link>
        </Grid>
        <Grid item>
            <LandingButton 
                title='Login'
                link='/auth/signin'
            />
        </Grid>
    </Grid>

export default BlogHeader