import  React from 'react';

import Button from '@mui/material/Button';

const LandingButton = ({
    title,
    link,
}) => 

 <Button href={link} 
        variant='contained' 
        sx={{ backgroundColor: "#6415ff", 
            '&:hover': {
                backgroundColor: '#5010CC',
            },
        }} >
    {title}
</Button>

export default LandingButton