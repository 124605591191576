import React  from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
// import { ReactQueryDevtools } from 'react-query/devtools'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0
    }
  }
})

ReactDOM.render(
         <QueryClientProvider client={queryClient}>
            <Router>
              <Route path="/" component={App} />
            </Router>
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
     , document.getElementById('root'))

serviceWorker.unregister()