import React from "react";
import { getTranslations } from '../../utils'
import MDEditor from '@uiw/react-md-editor';

import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import FormControl from '@mui/material/FormControl'
import Typography from "@mui/material/Typography"
import TextSnippetIcon from '@mui/icons-material/Description'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import PublishIcon from '@mui/icons-material/Publish';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import Divider from "@mui/material/Divider";

import SnackAlert from '@components/Dialogs/SnackAlert'
import LoadingIconButton from '@components/Buttons/LoadingIconButton'
import LoadingIconButton1 from '@components/Buttons/LoadingIconButton1'
import LoadingIconButton2 from '@components/Buttons/LoadingIconButton2'
import PostContent  from '@components/Blog/PostContent'
import LangDropdown from '@components/DropDowns/LangDropdown'

export default function UpdatePostPage({ 
    lang, 
    title,
    text,
    loading,
    handleTitle,
    handleText,
    addText,
    errorText,
    snack,
    msgType,
    alertMsg,
    handleClose,
    summary, 
    handleSummary, 
    handleLang,
    tag,
    tags,
    handleTag,
    post_image,
    handlePost_image,
    authorName,
    featured,
    handleFeatured,
    published,
    publishPost,
 }){

    const {
      en,
      es,
      fr,
      de,
   } = getTranslations()

    const langugages = [
      {lang:'de', language:de},
      {lang:'en', language:en},
      {lang:'es', language:es},
      {lang:'fr', language:fr},
    ]
  
    const date =  new Date().toUTCString() 
   
    const tagName = tags.length>0 ?  tags.filter(t => t.id == tag)[0].tag_name : ''

    return(
     
      <main >
         <Card sx={{
            padding: 3,
         }}> 
            <Grid container  
                  alignItems="center" 
                  spacing={2} 
                  justifyContent='space-between'
            >
              <Grid item xs={12}> 
                <Typography variant="h5" sx={{color:'#6415ff'}}>
                  Update Post
                </Typography>
              </Grid>
              <Grid item xs={12}> 
                <FormControl sx={{ width: '100%'}}>
                  <TextField 
                    variant="outlined"
                    fullWidth
                    multiline
                    error={errorText}
                    sx={{ width:'100%'}}
                    required 
                    label="Title" 
                    value={title} 
                    onChange={handleTitle} 
                  />
                </FormControl>  
              </Grid>
              <Grid item xs={12}> 
                <FormControl sx={{ width: '100%'}}>
                  <TextField 
                    variant="outlined"
                    fullWidth
                    multiline
                    error={errorText}
                    sx={{ width: '100%'}}
                    required 
                    label="Summary" 
                    value={summary} 
                    onChange={handleSummary} 
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}> 
                <FormControl sx={{ width: '100%'}}>
                  <TextField 
                    variant="outlined"
                    fullWidth
                    multiline
                    error={errorText}
                    sx={{ width:'100%'}}
                    required 
                    label="Post Image" 
                    value={post_image} 
                    onChange={handlePost_image} 
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}> 
                <MDEditor
                  value={text}
                  onChange={handleText}
                />
              </Grid>
              <Grid item  >
                <FormControl  fullWidth  sx={{minWidth: 150}}>
                  <InputLabel >
                    Tag
                  </InputLabel>
                  <Select
                    label='Tag'
                    value={tag}
                    onChange={handleTag}
                    sx={{backgroundColor: 'white'}}
                  >
                    {tags.map(t => 
                      <MenuItem key={t.id} value={t.id}>
                        {t.tag_name}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item  >
                <LangDropdown 
                  label='Language'
                  value={lang}
                  onChange={handleLang}
                  languages={langugages}
                />
              </Grid>
              <Grid item  >
              <FormControlLabel 
                control={<Checkbox 
                    onChange={handleFeatured} 
                    checked={featured}
                    size="large" 
                    sx={{
                      color: '#6415ff',
                      '&.Mui-checked': {
                        color: '#6415ff',
                      },
                    }}
                    />} 
                label=" Featured Article" 
                />
      
               
              </Grid>
              <Grid item  >
                <LoadingIconButton
                  size='large'
                  onClick={addText}
                  title='Update Post'
                  loading={loading}
                  icon={<TextSnippetIcon />}
                /> 
              </Grid>
              <Grid item  >
                {published ?
                  <LoadingIconButton2
                    size='large'
                    onClick={publishPost}
                    title='Unpublish Post'
                    loading={loading}
                    icon={<UnpublishedIcon />}
                  /> 
                  :
                  <LoadingIconButton1
                    size='large'
                    onClick={publishPost}
                    title='Publish Post'
                    loading={loading}
                    icon={<PublishIcon />}
                  /> 
                }
              </Grid>
            </Grid>
          </Card>   
          <Card sx={{p:3, mt: 1}}>
            <Typography variant="h5" sx={{color:'#6415ff'}}>
              Preview
            </Typography>
            <Divider sx={{mt:1, mb:1}} />
            <PostContent 
              date={date}
              title={title}
              authorName={authorName}
              summary={summary}
              article_text={text}
              tag={tagName}
              post_image={post_image}
              lang={lang}
            />
          </Card>
          <SnackAlert 
            snack={snack}
            handleClose={handleClose}
            msgType={msgType}
            alertMsg={alertMsg}
          /> 
        </main>
    )
}