import React from 'react'

import TextField from '@mui/material/TextField'


export default function LoginInput({
  name, 
  label, 
  handleFunc, 
  value, 
  error,
}){
return(
      <TextField
        error={error.length>0 ? true : false}
        helperText={error}
        variant="outlined"
        color='secondary'
        required
        sx={{backgroundColor: 'white'}}
        fullWidth
        name={name}
        label={label}
        type={name}
        id={name} 
        autoComplete={name === "password" ? "current-password" :"email"}
        onChange={handleFunc}
        value={value}
      />
)}