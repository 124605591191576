
import en from './en.json'
import es from './es.json'
import de from './de.json'
import fr from './fr.json'

const translations = { 
    en, 
    es, 
    fr,
    de,
}

export default translations
