import React, {useState} from "react"

import { 
  useQuery,
} from "react-query"

import {
  getPosts, 
} from '@queries/blogQueries'

import { deployCF } from "../utils"

import PostListPage from '@components/Pages/PostListPage'

export default function PostList(published){

  const [ loading, setLoading ] = useState(false)
  const [ alertMsg, setAlertMsg ] = useState('')
  const [ msgType, setMsgType ] = useState('info')
  const [ snack, setSnack ] = useState(false)

  const handleClose = () => setSnack(false)

  const showSnack = (type, message) => {
    setLoading(false)
    setSnack(true)
    setMsgType(type)
    setAlertMsg(message)
  }

    const { 
      isLoading, 
      // isError, 
      // error, 
      data,
     } = useQuery('posts', () => getPosts())


    const posts = !isLoading ?  data : []

    const publishPosts = async () => {
      try {
        await deployCF()
      } catch(error){
        showSnack('error', 'Publising error!')
      }
    }

    return <PostListPage
            title='Posts'
            posts={posts}
            publishPost={publishPosts}
            loading={loading}
            snack={snack}
            msgType={msgType}
            alertMsg={alertMsg}
            handleClose={handleClose}
          />
}