import React from "react"
import { useParams } from "react-router-dom"

import { 
  useQuery,
} from "react-query"

import {
  getTags, 
  getPostsAuthor,
} from '@queries/blogQueries'

import PostListPage from '@components/Pages/PostListPage'

export default function AuthorPostList(){

    const { 
      member_id, 
    } = useParams()

    const { isLoading, 
      // isError, 
      // error, 
      data: posts } = useQuery('posts', () => getPostsAuthor({member_id}))
    const { data: tags } = useQuery('tags', () => getTags())

    const posts1 = !isLoading ? posts: []
    
    return <PostListPage
            title='Posts'
            posts={posts1}
            loading={isLoading}
            tags={tags}
          />
}