import React from 'react'
import { useHistory } from 'react-router-dom'

import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

import grey from '@mui/material/colors/grey';

export default function SectionCard({
  path,
  title,
  icon,
}){
    const history = useHistory()
    const goToPage = () => history.push(path)

    return  <Card sx={{
                cursor: 'pointer',
                marginBottom:  1,
                backgroundColor: '#6415ff',
                  '&:hover': {
                    backgroundColor: '#5010CC',
                  },
                color:'white',
                }}
              onClick={goToPage}
            >
          
              <Grid container
                    alignItems='center'
                    sx={{marginLeft: 3}}
                >
                  <Grid item>
                    {icon}
                  </Grid>
                  <Grid item>
                    <Typography 
                      sx={{
                          padding: 3,
                          textDecoration: 'none',
                          color: grey[50] // 'white',
                        }} 
                        variant="h6" 
                      >
                      {title}
                    </Typography>
                  </Grid>
              </Grid>
            </Card>
  }