import React from 'react'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { red } from '@mui/material/colors';

import LoginHeader  from '@components/Login/LoginHeader'
import LoginHelp from '@components/Login/LoginHelp'
import Copyright from '@components/Login/Copyright'

export default function NotFoundPage(){

  return (
    <Container component="main" maxWidth="sm">
      <Paper sx={{ padding: 2, marginTop: 2, backgroundColor: 'rgb(240, 246, 255)' }}> 
        <Grid container 
              justifyContent='center' 
              textAlign='center'
              alignItems='center'
              spacing={2}
        >
           <Grid item xs={12}>
            <LoginHeader title= {`Langa Learn`} />
          </Grid>  
          <Grid item  xs={12}>
            <Typography variant="h3" color={red['A400']}>
              404
            </Typography>
          </Grid>
          <Grid item  xs={12}>
            <Typography variant="h4" color={red['A400']}>
              Page Not Found!
            </Typography>
          </Grid>
        </Grid>
        <Box mt={3}>
          <LoginHelp />
        </Box>
        <Box mt={3}>
          <Copyright />
        </Box>
      </Paper>  
    </Container>
  )
}