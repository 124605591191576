import React from 'react'
import { Link } from 'react-router-dom'

import logo from '../../assets/langa-logo-192.png'

const LangaLogo = ({size = 8}) => {
    return <Link to='/'>
            <img src={logo} style={{
              height: size * 10,
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: 16,
              marginBottom: 16,
              borderRadius: '10%',
            }} alt="langalogo" />
          </Link>
}

export default LangaLogo
