import { useQuery } from "react-query"

 import {queryClient} from '../index'

import {slugify} from '../utils'
 
import {supabase} from "../supabase"

const table = 'blogs'

export const postQuery = `
    id,
    title, 
    article_text,
    summary,
    published_date,
    post_image,
    featured,
    lang,
    published,
    members(
        id,
        name
    ),
    tags(
        id,
        tag_name
    )`

export const createPost =  async ({
    title,
    lang, 
    featured,
    summary,
    article_text,
    post_image,
    tag,
    author_id, 
}) => {
    const now = new Date()
    const slug = slugify(title)
    const { data, error } = await supabase
    .from(table)
    .insert({
       title,
       slug,
       lang, 
       featured,
       summary,
       article_text,
       published_date: now,
       post_image,
       tag_id: tag,
       author_id,
       published: false
})

    if(error) {
        throw new Error(error.message)
    }

    return data
}

export const updatePost = async update => {
    const { data, error } = await supabase
        .from(table)
        .update(update)
        .eq('id', update.id)

    if(error) {
        throw new Error(error.message)
    }

    return data
}

export const getPost = async ({id}) => {
    const { data, error } = await supabase
      .from(table)
      .select('*')
      .eq('id', id)
      .single()
  
    if(error) {
      throw new Error(error.message)
    }
  
    if(!data) {
      throw new Error("Post not found")
    }
  
    return data
  }

export const getPosts = async () => {
    const { data, error } = await supabase
    .from(table)
    .select(postQuery)
    .order('published_date',{ascending: false})
    .limit(10)

if(error) {
    console.log(error)
    throw new Error(error.message)
}

if(!data) {
    throw new Error("Cluster not found")
}

return data
}

export const getPostsAuthor = async ({member_id}) => {
    const { data, error } = await supabase
    .from(table)
    .select(postQuery)
    .eq('author_id', member_id)

if(error) {
    console.log(error)
    throw new Error(error.message)
}

if(!data) {
    throw new Error("Cluster not found")
}

return data
}

export const uploadImage = async ({
    filePath, 
    file,
}) => {
    let { error: uploadError, data } = await supabase.storage
    .from('blogimages')
    .upload(filePath, file, {
        cacheControl: '3600',
        upsert: false
      })
    console.log(uploadError)
    if(uploadError) throw uploadError
    console.log(data)
}

export const getBlogImages = async () => {
    const { data, error } = await supabase.storage.from('blogimages').list()

    if(error) {
        throw new Error(error.message)
    }

    if(!data) {
        throw new Error("Images not found")
    }

    return data
}

export const getTags = async () => {
    const { data, error } = await supabase
    .from('tags')
    .select(`
        id,
        tag_name
        `)
    .order('tag_name',{ascending: true})

if(error) {
    console.log(error)
    throw new Error(error.message)
}

if(!data) {
    throw new Error("Cluster not found")
}

return data
}
 
export const usePosts = () => useQuery('posts', () => getPosts())

export const prefetchPosts = async () => await queryClient.prefetchQuery('posts', getPosts())
