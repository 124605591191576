import React from "react";
import { Link } from 'react-router-dom'
import {langSwitch} from '../../utils'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import FlagRound from "@components/ImageElements/FlagRound"

const CountItem = ({
    lang,
    count,
}) => {
    const language = langSwitch(lang)
    const cntstr = count.toLocaleString(undefined)
  return(
    <Grid item>
        <Link to={{ pathname: `/user/lang_stats/${lang}`, state: { lang: lang } }} >
            <ListItem button>
                <ListItemIcon>
                    <FlagRound
                        lang={lang}
                        flagSize='xsmall'
                    />
                </ListItemIcon>
                <ListItemText primary={`${language} ${cntstr}`} />
            </ListItem>
        </Link >
    </Grid>
  )
}

export default function AllLangStats({counts}){
    return(
        <Card style={{margin:10}}>
            <CardContent>
                <Grid container  alignItems="center" justify="space-between">
                    <Grid item xs={12}>
                        <Typography variant="h6" style={{marginLeft:20}}>
                            Article Totals
                        </Typography>
                    </Grid>
                    {counts.map(c => 
                        <CountItem key={c.lang}  {...c} />
                    )}
                </Grid>
            </CardContent>
        </Card>
    )
}


