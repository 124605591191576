import React from "react"

import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

const drawerWidth = 240;

const AppBarTheme = styled('div')(({ theme }) => ({
    backgroundColor: '#6415ff',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      display: 'none',
    },
  }));

const MenuButtonTheme = styled('div')(({ theme }) => ( {
  marginRight: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));


  export default function AppBarHeader({handleDrawerToggle}){

    return(
      <AppBarTheme>
        <AppBar position="fixed" 
                sx={{
                  backgroundColor: '#6415ff',
                  color:'#b1ff15'
                }} 
        >
          <Toolbar>
            <MenuButtonTheme>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            </MenuButtonTheme>
            <Typography variant="h6" noWrap>
              Langa Learn
            </Typography>
          </Toolbar>
        </AppBar>
      </AppBarTheme>
    )
  }