import React from "react";

import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import FormControl from '@mui/material/FormControl'
import Typography from "@mui/material/Typography"
import SearchIcon from '@mui/icons-material/Search'
import red from '@mui/material/colors/red'

import SnackAlert from '@components/Dialogs/SnackAlert'
import LoadingIconButton from '@components/Buttons/LoadingIconButton'
import PostListItem from '@components/ListItems/PostListItem'

import { Divider } from "@mui/material";

const NoResults = () => <Typography color={red['A700']}>No results</Typography>

export default function SearchPage({ 
    title,
    text,
    loading,
    handleTitle,
    handleText,
    searchPosts,
    searchTitle,
    errorText,
    snack,
    msgType,
    alertMsg,
    handleClose,
    searchResults,

 }){
    return(
      <main >
         <Card sx={{
            padding: 3,
         }}> 
            <Grid container  
                  alignItems="center" 
                  spacing={2} 
                  justifyContent='space-between'
            >
              <Grid item xs={12}> 
                <Typography variant="h5" sx={{color:'#6415ff'}}>
                  Search Posts
                </Typography>
              </Grid>
              <Grid item xs={6}> 
                <FormControl sx={{ width: '100%'}}>
                  <TextField 
                    variant="outlined"
                    fullWidth
                    multiline
                    error={errorText}
                    sx={{ width:'100%'}}
                    required 
                    label="Title" 
                    value={title} 
                    onChange={handleTitle} 
                  />
                </FormControl>  
              </Grid>

              <Grid item xs={6}> 
                <FormControl sx={{ width: '100%'}}>
                  <TextField 
                    variant="outlined"
                    fullWidth
                    multiline
                    error={errorText}
                    sx={{ width:'100%'}}
                    required 
                    label="Post Text" 
                    value={text} 
                    onChange={handleText} 
                  />
                </FormControl>  
              </Grid>
              <Grid item  >
                <LoadingIconButton
                  size='large'
                  onClick={searchTitle}
                  title='Search Title'
                  loading={loading}
                  icon={<SearchIcon />}
                /> 
              </Grid>
              <Grid item  >
                <LoadingIconButton
                  size='large'
                  onClick={searchPosts}
                  title='Search Post Text'
                  loading={loading}
                  icon={<SearchIcon />}
                /> 
              </Grid>
            </Grid>
          </Card>   
          <Card sx={{p:3, mt: 1}}>
            <Typography variant="h5" sx={{color:'#6415ff'}}>
              Search Results
            </Typography>
            <Divider sx={{mt:1, mb:1}} />
            {searchResults.length>0 ? 
              <React.Fragment>
                {searchResults.map(r => 
                  <PostListItem {...r} key={r.id} />
                )}
              </React.Fragment>
              :
              <NoResults />
            }
        </Card>
          <SnackAlert 
            snack={snack}
            handleClose={handleClose}
            msgType={msgType}
            alertMsg={alertMsg}
          /> 
        </main>
    )
}