import React from 'react'

import { 
  useQuery,
} from "react-query"

import {getMember} from '@queries/memberQueries'

import ListAlt from '@mui/icons-material/ListAlt'
import AccountCircle from '@mui/icons-material/AccountCircle'
import GroupWork from '@mui/icons-material/GroupWork'

import DashboardPage from '@components/Pages/DashboardPage'

export default function Dashboard(){

  const { 
    isLoading, 
    // isError, 
    data: user,
   } = useQuery('member', () => getMember())
   
  const userName = user ?  user.name : ''

  const items = [
    {
      path:'profile',
      title:`Welcome ${userName}!`,
      icon: <AccountCircle />
    },
    {
      path:'/user/posts',
      title: 'Posts',
      icon: <ListAlt />
    },
    {
      path:'/user/stats',
      title: 'Statistics',
      icon: <GroupWork />
    },
  ]
  
  return  <DashboardPage
            loading={isLoading} 
            items={items} 
          />
}
